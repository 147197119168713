import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || '',
    stores: [],
    companies: [],
    countries: [],
    companiesBackup: [],
    storesBackup: [],
    searchStore: ''
  },
  getters: {
    isAuthenticated (state) {
      return !!state.token
    },
    getUser (state) {
      return state.user
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setUser (state, user) {
      state.user = user
    },
    clearAuthData (state) {
      state.token = null
      state.user = null
    },
    SET_STORES (state, stores) {
      state.stores = stores
      state.storesBackup = [...stores]
    },
    SET_COMPANIES (state, companies) {
      state.companies = companies
      state.companiesBackup = [...companies]
    },
    SET_COUNTRIES (state, countries) {
      state.countries = countries
    },
    SET_SEARCH_STORE (state, searchStore) {
      state.searchStore = searchStore
    }
  },
  actions: {
    saveAuthData ({ commit }, { token, user }) {
      commit('setToken', token)
      commit('setUser', user)
    },
    logout ({ commit }) {
      commit('clearAuthData')
    },
    async GetContriesNstoresNcompany ({ commit }) {
      try {
        const response = await axios.get('https://www.teebtop.com/dashboard/api_coupons')
        commit('SET_STORES', response.data.stores)
        commit('SET_COMPANIES', response.data.companies)
        commit('SET_COUNTRIES', response.data.countries)
      } catch (error) {
        console.error('error:', error)
      }
    },
    updateSearchStore ({ commit }, searchStore) {
      commit('SET_SEARCH_STORE', searchStore)
    },
    // Action to restore stores from backup or filter based on search term
    filterStores ({ commit, state }) {
      if (!state.searchStore) {
        commit('SET_STORES', [...state.storesBackup]) // Reset to original stores
      } else {
        const filteredStores = state.storesBackup.filter(store => {
          return store.name_ar.toLowerCase().includes(state.searchStore.toLowerCase())
        })
        commit('SET_STORES', filteredStores)
      }
    }
  },
  modules: {
  }
})
