import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.config.productionTip = false
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token') // Or get it from Vuex store
  if (token) {
    config.headers.token = `${token}`
  }
  return config
}, error => {
  return Promise.reject(error)
})
axios.defaults.baseURL = 'https://www.teebtop.com/dashboard/'
Vue.use(VueAxios, axios)
Vue.use(AOS)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
