<template>
  <v-app style="background-color:#e0ecf8">
    <v-navigation-drawer
      v-if="this.$store.state.token"
      color="#E0ECF8"
      disable-resize-watcher="True"
      v-model="drawer"
      right
      app
      style="z-index: 2; min-height: 100vh;"
      class="bar"
    >
    <img class="ma-auto d-flex justify-center mt-7 mb-n1" src="./assets/couponsGatelogo.png" width="50px" height="50px" alt="">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="arabic-bold d-flex justify-center" style="letter-spacing: 0.05cap; font-size: 11px; color: #4378a0;">بوابة الكوبونات لوحة التحكم</v-list-item-title>

              <v-chip class="roboto text-center d-flex justify-center mt-2" color="#bcd6e5" text-color="#4378a0" style="font-size: small; letter-spacing: 0.03cap;">
            {{ this.$store.state.user || 'Guest' }}
          </v-chip>
          <v-btn class="arabic d-flex justify-center" @click="logout" text style="letter-spacing: 0.05cap; width: 100%; font-size: 13px; color: #db2f2f;">تسجيل الخروج</v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-list dense class="arabic-bold">
  <v-list-item-group v-model="selectedItem" color="#4378A0">
    <v-list-item v-for="(item, i) in filteredItems(items)" :key="i" :to="item.link" class="rounded-lg py-1" link>
      <v-list-item-icon>
        <v-icon size="x-large" class="ml-n16">{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="arabic-bold">{{ item.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>

  <!-- قسم الاعلانات -->
  <v-list-group color="#4378A0">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="arabic-bold">
          <v-icon size="x-large"> mdi-advertisements</v-icon>
          قسم الإعلانات
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item v-for="(ad, i) in filteredItems(adsSection)" :key="i" :to="ad.link" link class="py-1">
      <v-list-item-icon>
        <v-icon>{{ ad.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ ad.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>

  <!-- قسم الكوبونات -->
  <v-list-group :value="true" color="#4378A0">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title class="arabic-bold">
          <v-icon size="x-large"> mdi-ticket-percent</v-icon>
          قسم الكوبونات
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item v-for="(sec, i) in filteredItems(couponsSection)" :key="i" :to="sec.link" link class="py-1">
      <v-list-item-icon>
        <v-icon>{{ sec.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ sec.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>

  <v-list-item-group v-model="selectedItem" color="#4378A0">
    <v-list-item v-for="(item, i) in filteredItems(items2)" :key="i" :to="item.link" class="rounded-lg py-1" link>
      <v-list-item-icon>
        <v-icon size="x-large" class="ml-n16">{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="arabic-bold">{{ item.text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</v-list>

    </v-navigation-drawer>
        <v-main >
          <v-btn icon large  @click="drawer = !drawer" color="#4378A0" class="navIcon" style="position: fixed;">
         <v-icon>
          mdi-menu
         </v-icon>
        </v-btn>
        <transition name="fade" mode="out-in">
        <router-view />
      </transition>
          <v-btn icon x-large v-scroll="onScroll" v-show="isVisible" @click="toTop" elevation="24" class="top" style="background-color: #4378A0;">
          <v-icon color="white">mdi-chevron-double-up</v-icon>
        </v-btn>
          <v-btn icon x-large v-scroll="onScroll" v-show="isVisible" @click="toBottom" elevation="24" class="bottom" style="background-color: #4378A0;">
          <v-icon color="white">mdi-chevron-double-down</v-icon>
        </v-btn>
        </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
  name: 'App',
  data () {
    return {
      direction: 'top',
      all_permissions: [],
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: true,
      right: true,
      bottom: false,
      left: false,
      transition: 'slide-y-reverse-transition',
      drawer: true,
      group: null,
      user: 'Moayad',
      isVisible: false,
      selectedItem: 1,
      items: [
        { text: 'المدير', icon: 'mdi-cog-outline', link: '/manager' },
        { text: 'إدارة المهام', icon: 'mdi-checkbox-marked-circle-auto-outline', link: '/tasks' }
      ],
      couponsSection: [
        { text: 'الكوبونات', icon: 'mdi-percent', link: '/' },
        { text: 'عروض التراكينغ لينك', icon: 'mdi-percent-circle', link: '/trakinglink' },
        { text: 'الإحصائيات', icon: 'mdi-chart-line', link: '/home' },
        { text: 'الدول', icon: 'mdi-earth', link: '/countries' },
        { text: 'الشركات', icon: 'mdi-office-building', link: '/companies' },
        { text: 'المتاجر', icon: 'mdi-store', link: '/stores' }
      ],
      adsSection: [
        { text: 'تحديث الكوبونات', icon: 'mdi-refresh', link: '/update-coupons' },
        { text: 'إنشاء الحملات', icon: 'mdi-plus-box-outline', link: '/campaigns' }
      ],
      items2: [
        { text: 'قارن فون', icon: 'mdi-cellphone', link: '/qaranphone' },
        { text: 'مقالات موقع راقي', icon: 'mdi-file-document', link: '/articles' },
        { text: 'مستخدمين بوت التلغرام', icon: 'mdi-account', link: '/telegram-users' },
        { text: 'الإشعارات', icon: 'mdi-bell-outline', link: '/notification' }
      ]

    }
  },
  watch: {
    $route (to, from) {
      // Set different transitions based on navigation direction
      this.transitionName = to.path > from.path ? 'slide' : 'fade'
    },
    top (val) {
      this.bottom = !val
    },
    right (val) {
      this.left = !val
    },
    bottom (val) {
      this.top = !val
    },
    left (val) {
      this.right = !val
    }
  },
  methods: {
    logout () {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token')
      console.log('Token:', token)
      // Check if a token exists
      if (!token) {
        console.error('No token found for logout.')
        window.location.href = 'http://dashboard.raqi.marketing/login'
        return
      }
      const axios = require('axios')
      axios.post(
        'https://www.teebtop.com/user/api/logout/',
        {
          headers: {
            token: token
          }
        }
      )
        .then(response => {
          console.log('Successfully logged out:', response.data)
          // Clear token and user data from localStorage/sessionStorage
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          sessionStorage.removeItem('token')
          this.$store.dispatch('clearAuthData') // Assuming you have a Vuex action to clear the token and user
          // Optionally redirect the user to the login page
          window.location.href = 'http://dashboard.raqi.marketing/login' // Adjust this to your login route
        })
        .catch(error => {
          console.error('Error during logout:', error.response ? error.response.data.message : error.message)
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          sessionStorage.removeItem('token')
          this.$store.dispatch('clearAuthData') // Assuming you have a Vuex action to clear the token and user
          // Optionally redirect the user to the login page
          window.location.href = 'http://dashboard.raqi.marketing/login' // Adjust this to your login route
        })
    },

    onScroll () {
      this.isVisible = window.scrollY > 50
    },
    toTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    toBottom () {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    },
    getAllPermissions () {
      axios.get('https://www.teebtop.com/dashboard/api_dashboard')
        .then(response => {
          this.all_permissions = response.data.all_permissions
          console.log(this, this.all_permissions)
        })
        .catch(error => {
          console.error('Error:', error.response)
        })
    }
  },
  mounted () {
    this.getAllPermissions()
  },
  computed: {
    filteredItems () {
      this.getAllPermissions()
      return (section) => {
        return section.filter(item => this.all_permissions.includes(item.text))
      }
    }
  }

}
</script>
<style >
@import url('https://fonts.googleapis.com/css2?family=Alexandria&family=Concert+One&family=El+Messiri&family=Lalezar&family=Marhey&family=Montserrat:wght@500&family=PT+Sans+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border{
width: 0px;
}
.v-application--is-rtl .v-list-item__icon:last-of-type:not(:only-child){
  margin-right: 0px;
}
.top{
  position: fixed !important;
  bottom: 30px;
  left: 40px;
  z-index: 10000;
}
.bottom{
  position: fixed !important;
  bottom: 30px;
  left: 100px;
  z-index: 10000;
}
/* Custom scrollbar for webkit browsers (Chrome, Safari) */
.bar::-webkit-scrollbar {
  width: 2px; /* width of the scrollbar */
}
.bar::-webkit-scrollbar-track {
  background: #E0ECF8; /* track color */
}
.bar::-webkit-scrollbar-thumb {
  background: #E0ECF8; /* scrollbar color */
}
.bar::-webkit-scrollbar-thumb:hover {
  background: #E0ECF8; /* scrollbar color on hover */
}

/* Custom scrollbar for Firefox */
.bar {
  scrollbar-width: 2px !important; /* width of the scrollbar */
  scrollbar-color: #E0ECF8 #E0ECF8; /* scrollbar color and track color */
}

.scroller {
    margin: 0 auto;
    width: 200px;
    height: 280px;
    overflow-y: scroll;
    background-color: #f8e9f2;
    border: solid 4px #DE94BF;
    border-radius: 5px;
}

.scroller::-webkit-scrollbar {
    width: 8px;
    background-color: #DE94BF;
}

.scroller::-webkit-scrollbar-thumb {
    background: #FAF8D8;
    width: 6px;
    border-radius: 5px;
}
.navIcon{
  position: fixed;
  top: 5px;
  right: 4px;
  z-index: 10000;
}
.roboto-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.roboto {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.arabic-bold{
  font-family: 'Cairo', sans-serif !important;
  font-weight: 800 !important;
  line-height: inherit !important;
  letter-spacing: 0.01cap;
}
.arabic{
  font-family: 'Cairo', sans-serif !important;
  font-weight: 400;
  line-height: inherit !important;
  letter-spacing: 0.01cap;
}
.v-list-item--nav .v-list-item-title{
  font-weight: 500;
  font-size: 15px;
}
.v-navigation-drawer.v-navigation-drawer--absolute.v-navigation-drawer--floating.v-navigation-drawer--open.v-navigation-drawer--right.theme--light{
  width: 200px !important;
}
.v-application--is-rtl .v-list-item__action:first-child, .v-application--is-rtl .v-list-item__icon:first-child {
    margin-left: 8px;
}
/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease; /* Fade duration */
}

.fade-enter, .fade-leave-to {
  opacity: 0; /* Start and end opacity for fade-in/out */
}
</style>
