import Vue from 'vue'
import VueRouter from 'vue-router'
import CouponsView from '../views/CouponsView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home-view',
    component: CouponsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/update-coupons',
    name: 'update-coupons',
    component: () => import('../views/AboutView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/stores',
    name: 'update-coupons',
    component: () => import('../views/StoresView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('../views/CountriesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('../views/CompaniesView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/trakinglink',
    name: 'trakinglink',
    component: () => import('../views/TrakingLinkView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('../views/CampaignsView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('../views/ArticleView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manager',
    name: 'manager',
    component: () => import('../views/ManagerView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/telegram-users',
    name: 'telegram-users',
    component: () => import('../views/TelegramView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('../views/TaskView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('../views/NotificationView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/qaranphone',
    name: 'qaranphone',
    component: () => import('../views/QarenView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const token = store.state.token

  if (to.meta.requiresAuth && !token) {
    console.log('No token found, redirecting to /login')
    next('/login') // Redirect to login
  } else {
    next() // Proceed to route
  }
  if (token && to.path === '/login') {
    next('/')
  } else {
    next() // Allow navigation for all other cases
  }
})
export default router
